import { Layout } from '../components/ui/Layout';
import { TextHeader } from '../components/layout/TextHeader';
import { TextPage } from '../components/ui/text/TextPage';
import React from 'react';
import { Text } from '../components/ui/typography/Text';
import { Link } from 'gatsby';
import IconBulb from '../img/bulb.svg';
import loadable from '@loadable/component';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Helmet = loadable(() => import('react-helmet'));

export default () => (
	<Layout>
		<Helmet>
			<title>PageSpeed.cz - Často kladené dotazy</title>
		</Helmet>
		<TextHeader headline="Často kladené dotazy" />
		<TextPage>
			<h2>Proč služba vznikla?</h2>
			<Text>
				<OutboundLink href="https://superkoders.com/" target="_blank" rel="noopener noreferrer">
					SUPERKODERS
				</OutboundLink>{' '}
				se spojili s{' '}
				<OutboundLink href="https://www.vzhurudolu.cz/martin" target="_blank" rel="noopener noreferrer">
					Martinem Michálkem
				</OutboundLink>
				, protože máme rádi rychlé weby a <Link to="/audit">umíme vám pomoci</Link> s jejich optimalizací.
			</Text>
			<Text>
				Nástroj{' '}
				<OutboundLink
					href="https://developers.google.com/speed/pagespeed/insights/"
					target="_blank"
					rel="noopener noreferrer"
				>
					PageSpeed Insights
				</OutboundLink>{' '}
				uznáváme jako výborný zdroj informací o rychlosti webu. Ale chybí nám v něm dva rozměry:
			</Text>
			<ul>
				<li>dlouhodobé sledování rychlosti</li>
				<li>bezprostřední srovnání</li>
			</ul>
			<Text>
				V našem testeru rychlosti tedy můžete porovnat různé stránky a nechat si průběžně posílat e-mailem
				informace o tom, jak si stojíte.
			</Text>
			<div className="b-tip">
				<IconBulb />
				<Text>
					<strong>Náš tip:</strong> Srovnejte váš web s konkurencí a v druhém testu si porovnejte rychlost
					různých vstupních stránek vašeho webu.
				</Text>
			</div>

			<h2>Proč mají testy některých webů delší historii?</h2>
			<Text>
				Určitá URL už mohl zkoušet měřit někdo před vámi. Nebo my sami. V takovém případě máme dobrou zprávu – v
				testu vám zobrazíme celou historii tohoto měření.
			</Text>
			<Text>
				Ve vašem testu pak mají různé weby různě dlouhou historii. Prostě jsme vás nechtěli připravit o zajímavá
				čísla z doby, kdy jste ještě tento test rychlosti nevytvořili.
			</Text>

			<h2>Jak výsledky měříme?</h2>
			<Text>
				Weby měříme pomocí{' '}
				<OutboundLink
					href="https://developers.google.com/speed/docs/insights/v5/get-started"
					target="_blank"
					rel="noopener noreferrer"
				>
					PageSpeed Insights API
				</OutboundLink>
				, vždy v nočních hodinách přes{' '}
				<OutboundLink href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">
					Amazon Web Services
				</OutboundLink>
				.
			</Text>

			<h2>Jaké metriky měříme a jak jsou odstupňované?</h2>
			<Text>
				Strojem měříme{' '}
				<OutboundLink
					href="https://www.vzhurudolu.cz/prirucka/metrika-lps"
					target="_blank"
					rel="noopener noreferrer"
				>
					Lighthouse Performance Score
				</OutboundLink>{' '}
				a z uživatelských dat{' '}
				<OutboundLink
					href="https://www.vzhurudolu.cz/prirucka/chrome-ux-report"
					target="_blank"
					rel="noopener noreferrer"
				>
					Chrome UX Reportu
				</OutboundLink>{' '}
				pak získáváme čísla pro{' '}
				<OutboundLink
					href="https://www.vzhurudolu.cz/prirucka/metrika-fcp"
					target="_blank"
					rel="noopener noreferrer"
				>
					First Contentful Paint
				</OutboundLink>{' '}
				(První vykreslení obsahu) a{' '}
				<OutboundLink
					href="https://www.vzhurudolu.cz/prirucka/metrika-fid"
					target="_blank"
					rel="noopener noreferrer"
				>
					First Input Delay
				</OutboundLink>{' '}
				(První nečinnost procesoru).
			</Text>
			<table className="table-responsive">
				<thead>
					<tr>
						<th>Metrika</th>
						<th>
							Rychlá
							<br />
							<span>(zelená)</span>
						</th>
						<th>
							Průměrná
							<br />
							<span>(oranžová)</span>
						</th>
						<th>
							Pomalá
							<br />
							<span>(červená)</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>LPS (Lighthouse Performance Score)</th>
						<td data-table="Rychlá (zelená)">90 - 100</td>
						<td data-table="Průměrná (oranžová)">50 - 89</td>
						<td data-table="Pomalá (červená)">0 - 49</td>
					</tr>
					<tr>
						<th>FCP (First Contentful Paint)</th>
						<td data-table="Rychlá (zelená)">méně než 1 s</td>
						<td data-table="Průměrná (oranžová)">1 - 3 s</td>
						<td data-table="Pomalá (červená)">více než 3 s</td>
					</tr>
					<tr>
						<th>FID (First Input Delay)</th>
						<td data-table="Rychlá (zelená)">méně než 100 ms</td>
						<td data-table="Průměrná (oranžová)">100 - 300 ms</td>
						<td data-table="Pomalá (červená)">více než 300 ms</td>
					</tr>
				</tbody>
			</table>
			<Text>
				V tabulce zobrazujeme 75. percentil FCP a 95. percentil FID. Více o volbě těchto percentilů je v
				dokumentaci{' '}
				<OutboundLink
					href="https://developers.google.com/speed/docs/insights/v5/about#faq"
					target="_blank"
					rel="noopener noreferrer"
				>
					měřícího API
				</OutboundLink>
				.
			</Text>
			<Text>Data z Chrome UX Reportu jsou pro posledních 30 dní od chvíle spuštění testu.</Text>

			<h2>Jak je nastavené měřící zařízení?</h2>
			<Text>
				PageSpeed Insights API měří pomocí emulace zařízení Moto G4 a napodobuje rychlejší{' '}
				<OutboundLink
					href="https://github.com/GoogleChrome/lighthouse/blob/master/docs/throttling.md"
					target="_blank"
					rel="noopener noreferrer"
				>
					mobilního připojení 3G
				</OutboundLink>
				.
			</Text>

			<h2>Proč udáváme čísla jen za test v mobilu?</h2>
			<Text>
				Veřejné rozhraní PageSpeed Insights zobrazuje dva testy – mobil a počítač. My měříme jen mobily. Má to
				tři důvody:
			</Text>
			<ol>
				<li>
					Právě u mobilních návštěvníků bývají weby výrazně pomalejší. Není to jen rychlostí internetu na
					mobilních sítích, ale také obvykle slabším výkonem přenosných zařízení.
				</li>
				<li>
					Většina dnešních webů má responzivní architekturu, takže vylepšení mobilního kódu přinese zlepšení i
					na desktopu.
				</li>
				<li>
					Uživatelé mobilů dnes u velké části webů tvoří většinu návštěvnosti a my si myslíme, že dříve či
					později začnou převažovat všude.
				</li>
			</ol>
			<Text>
				Pokud by vás přesto zajímalo, jak si stojíte na desktopu, otestujte si jej jednorázově na{' '}
				<OutboundLink
					href="https://developers.google.com/speed/pagespeed/insights/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Pagespeed Insights
				</OutboundLink>
				.
			</Text>

			<h2>Proč se občas liší čísla u vás a na Pagespeed Insights?</h2>
			<Text>
				Pravidelně testujeme v nočních hodinách (pro Českou republiku), abychom omezili ovlivnění výsledků
				běžným provozem na webu. Test na Pagespeed Insights je vykonáván vždy v okamžik zadání. Data z Pagespeed
				API u nás neprochází žádnou další interpretací.
			</Text>

			<hr />

			<h2>Další informace:</h2>
			<ul>
				<li>
					Dokumentace{' '}
					<OutboundLink
						href="https://developers.google.com/speed/docs/insights/v5/about"
						target="_blank"
						rel="noopener noreferrer"
					>
						PageSpeed Insights API
					</OutboundLink>
				</li>
				<li>
					Téma{' '}
					<OutboundLink
						href="https://www.vzhurudolu.cz/rychlost-nacitani"
						target="_blank"
						rel="noopener noreferrer"
					>
						rychlosti webu
					</OutboundLink>{' '}
					na Vzhůru dolů.
				</li>
			</ul>
			<Text>
				Zajímá vás ještě něco dalšího? Napište nám na{' '}
				<OutboundLink href="mailto:info@pagespeed.cz">info@pagespeed.cz</OutboundLink>
			</Text>
		</TextPage>
	</Layout>
);
